import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { CONNECTION_STATE } from 'src/constants';

export const HubConnectionStatusLed = ({ getConnectionStatusColor, connectionState }) => {
  const isConnected = connectionState === CONNECTION_STATE.CONNECTED;

  return (
    <Box
      sx={{
        width: 10,
        height: 10,
        borderRadius: '50%',
        bgcolor: getConnectionStatusColor(),
        boxShadow: (theme) => `0 0 8px ${alpha(theme.palette.common.black, 0.25)}`,
        position: 'relative',
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: '50%',
          animation: isConnected ? 'pulse 2s infinite' : 'none',
          bgcolor: 'currentColor',
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1)',
              opacity: 0.8
            },
            '50%': {
              transform: 'scale(1.3)',
              opacity: 0
            },
            '100%': {
              transform: 'scale(1)',
              opacity: 0
            }
          }
        }
      }}
    />
  );
};

HubConnectionStatusLed.propTypes = {
  getConnectionStatusColor: PropTypes.func.isRequired,
  connectionState: PropTypes.oneOf(Object.values(CONNECTION_STATE)).isRequired
};
