export const CONNECTION_STATE = {
  DISCONNECTED: 'Disconnected',
  CONNECTING: 'Connecting',
  CONNECTED: 'Connected',
  RECONNECTING: 'Reconnecting',
  CONNECTION_ERROR: 'Connection Error'
};

export const CONNECTION_ERROR = {
  NO_TOKEN: 'No session token found',
  FAILED_TO_CONNECT: 'Failed to connect to the SignalR server',
  ATTEMPTING_RECONNECT: 'Attempting to reconnect...',
  DISCONNECTED: 'Connection disconnected'
};

export const HUB_TYPES = {
  TASK_QUEUE: 'taskQueue',
  WIDGET: 'widgets'
};

export const HUB_ACTIVITY = {
  TASK_QUEUE_HUB: {
    EVENT: {
      RECEIVE_TASK_CHAT_MESSAGE: 'ReceiveTaskMessage',
      RECEIVE_TASK_CHAT_MESSAGE_HISTORY: 'ReceiveMessageHistory',
      TASK_STATUS_UPDATED: 'TaskStatusUpdated',
      TASK_ASSIGNED: 'TaskAssigned',
      USER_TYPING: 'UserTyping'
    },
    ACTION: {
      JOIN_TASK_CHAT: 'JoinTaskChat',
      SEND_TASK_CHAT_MESSAGE: 'SendTaskChatMessage',
      LOAD_MORE_TASK_CHAT_MESSAGES: 'LoadMoreTaskChatMessages',
      SEND_TYPING_INDICATOR: 'SendTypingIndicator',
      LEAVE_TASK_CHAT: 'LeaveTaskChat',
      NOTIFY_TASK_STATUS_UPDATE: 'NotifyTaskStatusUpdate',
      UPDATE_TASK_STATUS: 'UpdateTaskStatus'
    }
  },
  WIDGET_HUB: {
    EVENT: {
      RECEIVE_WIDGET_DATA: 'ReceiveWidgetData'
    },
    ACTION: {
      SUBSCRIBE_TO_WIDGET: 'SubscribeToWidget',
      UNSUBSCRIBE_FROM_WIDGET: 'UnsubscribeFromWidget',
      SEND_WIDGET_DATA: 'SendWidgetData',
      LOAD_MORE_WIDGET_DATA: 'LoadMoreWidgetData'
    }
  }
};
