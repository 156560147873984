import { format, getTime, formatDistanceToNow } from 'date-fns';
import { tr } from 'date-fns/locale';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : '';
}

export function fRelativeTime(timestamp) {
  try {
    const now = new Date();
    const activityTime = new Date(timestamp);

    // If invalid date
    if (isNaN(activityTime.getTime())) {
      return '';
    }

    const diffMs = now - activityTime;
    const diffMins = Math.floor(diffMs / 60000);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);

    if (diffMins < 1) {
      return 'Az önce';
    } else if (diffMins < 60) {
      return `${diffMins} dakika önce`;
    } else if (diffHours < 24) {
      return `${diffHours} saat önce`;
    } else if (diffDays < 7) {
      return `${diffDays} gün önce`;
    } else {
      // Format as date
      return format(activityTime, 'dd MMM yyyy', { locale: tr });
    }
  } catch (error) {
    console.error('Date formatting error:', error);
    return '';
  }
}

export function fFullDateTime(timestamp) {
  try {
    const date = new Date(timestamp);
    return format(date, 'dd MMMM yyyy HH:mm:ss', { locale: tr });
  } catch (error) {
    console.error('Date formatting error:', error);
    return '';
  }
}
