import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
// components
import Iconify from 'src/components/iconify';
import { fRelativeTime, fFullDateTime } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function ActivityItem({ activity }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Stack
        spacing={1.5}
        onClick={handleOpen}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            bgcolor: alpha(theme.palette.primary.main, 0.04),
            borderRadius: 1,
            transition: theme.transitions.create('background-color', {
              duration: theme.transitions.duration.shorter
            })
          },
          p: 1
        }}
      >
        <Stack direction="row" alignItems="flex-start" spacing={2}>
          <Box
            sx={{
              width: 48,
              height: 48,
              flexShrink: 0,
              display: 'flex',
              borderRadius: 1,
              alignItems: 'center',
              justifyContent: 'center',
              color: activity.color || theme.palette.primary.main,
              bgcolor: alpha(activity.color || theme.palette.primary.main, 0.08)
            }}
          >
            <Iconify icon={activity.icon || 'solar:bell-bold'} width={24} />
          </Box>

          <Stack spacing={0.5} flexGrow={1}>
            <Typography variant="subtitle2">{activity.title}</Typography>

            <Typography
              variant="body2"
              sx={{
                color: 'text.secondary',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}
            >
              {activity.description}
            </Typography>

            <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              {fRelativeTime(activity.timestamp)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <ActivityDetailsDialog open={open} onClose={handleClose} activity={activity} />
    </>
  );
}

ActivityItem.propTypes = {
  activity: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    timestamp: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    details: PropTypes.object,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    actionType: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string
  }).isRequired
};

// ----------------------------------------------------------------------

function ActivityDetailsDialog({ open, onClose, activity }) {
  const theme = useTheme();

  // Get the appropriate icon and color for the entity type
  const getEntityTypeInfo = (entityType) => {
    switch (entityType?.toLowerCase()) {
      case 'task':
        return { icon: 'mdi:clipboard-text-outline', label: 'Görev' };
      case 'order':
        return { icon: 'mdi:shopping-outline', label: 'Sipariş' };
      case 'project':
        return { icon: 'mdi:folder-outline', label: 'Proje' };
      case 'variant':
        return { icon: 'mdi:shape-outline', label: 'Varyant' };
      case 'user':
        return { icon: 'mdi:account-outline', label: 'Kullanıcı' };
      default:
        return { icon: 'mdi:information-outline', label: entityType || 'Bilinmeyen' };
    }
  };

  // Get the appropriate icon and color for the action type
  const getActionTypeInfo = (actionType) => {
    switch (actionType?.toLowerCase()) {
      case 'create':
        return {
          icon: 'mdi:plus-circle-outline',
          label: 'Oluşturma',
          color: theme.palette.success.main
        };
      case 'update':
        return { icon: 'mdi:pencil-outline', label: 'Güncelleme', color: theme.palette.info.main };
      case 'delete':
        return { icon: 'mdi:delete-outline', label: 'Silme', color: theme.palette.error.main };
      case 'complete':
        return {
          icon: 'mdi:check-circle-outline',
          label: 'Tamamlama',
          color: theme.palette.success.main
        };
      case 'assign':
        return {
          icon: 'mdi:account-arrow-right-outline',
          label: 'Atama',
          color: theme.palette.warning.main
        };
      default:
        return {
          icon: 'mdi:information-outline',
          label: actionType || 'Bilinmeyen',
          color: theme.palette.grey[500]
        };
    }
  };

  const entityTypeInfo = getEntityTypeInfo(activity.entityType);
  const actionTypeInfo = getActionTypeInfo(activity.actionType);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" spacing={1} alignItems="center">
            <Box
              sx={{
                width: 40,
                height: 40,
                display: 'flex',
                borderRadius: 1,
                alignItems: 'center',
                justifyContent: 'center',
                color: activity.color || theme.palette.primary.main,
                bgcolor: alpha(activity.color || theme.palette.primary.main, 0.08)
              }}
            >
              <Iconify icon={activity.icon || 'solar:bell-bold'} width={24} />
            </Box>
            <Typography variant="h6">{activity.title}</Typography>
          </Stack>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <Iconify icon="mdi:close" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              {activity.description}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper
              variant="outlined"
              sx={{ p: 2, bgcolor: alpha(theme.palette.background.default, 0.6) }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Stack spacing={0.5}>
                    <Typography variant="caption" color="text.secondary">
                      Tarih ve Saat
                    </Typography>
                    <Typography variant="body2">{fFullDateTime(activity.timestamp)}</Typography>
                  </Stack>
                </Grid>

                {activity.entityType && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Stack spacing={0.5}>
                      <Typography variant="caption" color="text.secondary">
                        Nesne Tipi
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify icon={entityTypeInfo.icon} width={16} />
                        <Typography variant="body2">{entityTypeInfo.label}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}

                {activity.actionType && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Stack spacing={0.5}>
                      <Typography variant="caption" color="text.secondary">
                        İşlem Tipi
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify
                          icon={actionTypeInfo.icon}
                          width={16}
                          color={actionTypeInfo.color}
                        />
                        <Typography variant="body2">{actionTypeInfo.label}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}

                {activity.userName && (
                  <Grid item xs={12} sm={6} md={4}>
                    <Stack spacing={0.5}>
                      <Typography variant="caption" color="text.secondary">
                        Kullanıcı
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Iconify icon="mdi:account" width={16} />
                        <Typography variant="body2">{activity.userName}</Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>

          {activity.details && Object.keys(activity.details).length > 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Detaylar
              </Typography>
              <Paper variant="outlined" sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  {Object.entries(activity.details).map(([key, value]) => (
                    <Grid item xs={12} sm={6} md={4} key={key}>
                      <Stack spacing={0.5}>
                        <Typography variant="caption" color="text.secondary">
                          {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                        </Typography>
                        <Typography variant="body2" sx={{ wordBreak: 'break-word' }}>
                          {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                        </Typography>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        {activity.entityId && activity.entityType && (
          <Tooltip title="İlgili sayfaya git">
            <Button startIcon={<Iconify icon="mdi:arrow-right" />} onClick={onClose}>
              Detaylara Git
            </Button>
          </Tooltip>
        )}
        <Button onClick={onClose} color="primary">
          Kapat
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ActivityDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  activity: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    timestamp: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    details: PropTypes.object,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    actionType: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string
  }).isRequired
};
