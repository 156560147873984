import { m } from 'framer-motion';
import { useState, useEffect, useCallback, useRef } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
import { HubConnectionStatusLed } from 'src/components/HubConnectionStatusLed';
// SignalR
import { useSignalR } from 'src/contexts/signalr/SignalRProvider';
import { HUB_ACTIVITY, HUB_TYPES, CONNECTION_STATE } from 'src/constants';
// Activity Feed
import ActivityItem from 'src/sections/overview/app/activity-item';

// ----------------------------------------------------------------------

const ACTIVITY_PAGE_SIZE = 20;
const WIDGET_ID = 'activity-feed';

// Helper function to ensure each activity has a unique ID
const ensureActivityId = (activity) => {
  if (activity.id) return activity;

  // Generate a unique ID if one doesn't exist
  return {
    ...activity,
    id: `activity-${activity.timestamp}-${Math.random().toString(36).substr(2, 9)}`
  };
};

export function ActivityFeedPopover() {
  const drawer = useBoolean();
  const smUp = useResponsive('up', 'sm');
  const { getConnection, getConnectionState, setConnectionError } = useSignalR();
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [newActivitiesCount, setNewActivitiesCount] = useState(0);
  const subscriptionRef = useRef(false);
  const activityEndRef = useRef(null);

  const connection = getConnection(HUB_TYPES.WIDGET);
  const connectionState = getConnectionState(HUB_TYPES.WIDGET);

  const handleReceiveWidgetData = useCallback(
    (widgetId, data) => {
      if (widgetId !== WIDGET_ID) return;

      console.log('Received activity data:', data);

      // If drawer is closed, increment the new activities counter
      if (!drawer.value) {
        setNewActivitiesCount((prevCount) => prevCount + 1);
      }

      // Handle the new data structure where activities are wrapped in an object
      if (data && typeof data === 'object') {
        // Case 1: Data is an object with 'activities' array and 'hasMore' flag
        if (Array.isArray(data.activities)) {
          setActivities((prevActivities) => {
            // Ensure each activity has an ID and filter out duplicates
            const processedData = data.activities.map(ensureActivityId);
            const newActivities = processedData.filter(
              (activity) => !prevActivities.some((a) => a.id === activity.id)
            );

            // Sort by timestamp (newest first)
            const combined = [...prevActivities, ...newActivities].sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            );

            // Set hasMore based on the flag from the server
            setHasMore(data.hasMore);
            setIsLoading(false);
            setIsLoadingMore(false);

            return combined;
          });
          return;
        }

        // Case 2: Data is a single activity object
        if (data.id || data.timestamp) {
          const processedActivity = ensureActivityId(data);
          setActivities((prevActivities) => [processedActivity, ...prevActivities]);
          return;
        }
      }

      // Case 3: Data is an array of activities (original expected format)
      if (Array.isArray(data)) {
        setActivities((prevActivities) => {
          // Ensure each activity has an ID and filter out duplicates
          const processedData = data.map(ensureActivityId);
          const newActivities = processedData.filter(
            (activity) => !prevActivities.some((a) => a.id === activity.id)
          );

          // Sort by timestamp (newest first)
          const combined = [...prevActivities, ...newActivities].sort(
            (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
          );

          setHasMore(data.length === ACTIVITY_PAGE_SIZE);
          setIsLoading(false);
          setIsLoadingMore(false);

          return combined;
        });
      } else {
        // Case 4: Data is a single activity (original expected format)
        const processedActivity = ensureActivityId(data);
        setActivities((prevActivities) => [processedActivity, ...prevActivities]);
      }
    },
    [drawer.value]
  );

  const subscribe = useCallback(async () => {
    if (subscriptionRef.current || !connection) return;

    try {
      await connection.invoke(HUB_ACTIVITY.WIDGET_HUB.ACTION.SUBSCRIBE_TO_WIDGET, WIDGET_ID);
      subscriptionRef.current = true;
      setIsLoading(true);
    } catch (error) {
      console.error('[ActivityFeedPopover] Error subscribing:', error);
      setConnectionError(HUB_TYPES.WIDGET, 'Aktivite akışına abone olurken bir hata oluştu');
      setIsLoading(false);
    }
  }, [connection, setConnectionError]);

  const unsubscribe = useCallback(async () => {
    if (!subscriptionRef.current || !connection) return;

    try {
      await connection.invoke(HUB_ACTIVITY.WIDGET_HUB.ACTION.UNSUBSCRIBE_FROM_WIDGET, WIDGET_ID);
      subscriptionRef.current = false;
    } catch (error) {
      console.error('[ActivityFeedPopover] Error unsubscribing:', error);
    }
  }, [connection]);

  const loadMoreActivities = async () => {
    if (!connection || isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    const oldestActivity = activities[activities.length - 1];

    if (oldestActivity) {
      try {
        await connection.invoke(
          HUB_ACTIVITY.WIDGET_HUB.ACTION.LOAD_MORE_WIDGET_DATA,
          WIDGET_ID,
          oldestActivity.timestamp,
          ACTIVITY_PAGE_SIZE
        );
      } catch (error) {
        console.error('Error loading more activities:', error);
        setConnectionError(HUB_TYPES.WIDGET, 'Eski aktiviteler yüklenirken bir hata oluştu');
        setIsLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    if (!connection) return;

    connection.on(HUB_ACTIVITY.WIDGET_HUB.EVENT.RECEIVE_WIDGET_DATA, handleReceiveWidgetData);

    if (connectionState === CONNECTION_STATE.CONNECTED) {
      subscribe();
    }

    return () => {
      connection.off(HUB_ACTIVITY.WIDGET_HUB.EVENT.RECEIVE_WIDGET_DATA, handleReceiveWidgetData);
      unsubscribe();
    };
  }, [connection, connectionState, handleReceiveWidgetData, subscribe, unsubscribe]);

  // Reset counter when opening drawer
  useEffect(() => {
    if (drawer.value) {
      setNewActivitiesCount(0);
    }
  }, [drawer.value]);

  const getConnectionStatusColor = () => {
    switch (connectionState) {
      case CONNECTION_STATE.CONNECTED:
        return 'success.main';
      case CONNECTION_STATE.RECONNECTING:
        return 'warning.main';
      case CONNECTION_STATE.CONNECTION_ERROR:
      case CONNECTION_STATE.DISCONNECTED:
        return 'error.main';
      default:
        return 'grey.500';
    }
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Stack direction="row" alignItems="center" spacing={1} sx={{ flexGrow: 1 }}>
        <HubConnectionStatusLed
          getConnectionStatusColor={getConnectionStatusColor}
          connectionState={connectionState}
        />
        <Typography variant="h6">Olan Biten</Typography>
      </Stack>

      {!smUp && (
        <IconButton onClick={drawer.onFalse}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderList = (
    <Scrollbar>
      {isLoading && activities.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 3 }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <List disablePadding sx={{ px: 2, pb: 2 }}>
          {activities.map((activity) => (
            <Box key={activity.id} sx={{ mt: 2 }}>
              <ActivityItem activity={activity} />
            </Box>
          ))}

          {hasMore && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="text"
                color="inherit"
                disabled={isLoadingMore}
                startIcon={isLoadingMore ? <CircularProgress size={16} /> : null}
                onClick={loadMoreActivities}
              >
                {isLoadingMore ? 'Yükleniyor...' : 'Daha Fazla Yükle'}
              </Button>
            </Box>
          )}

          <div ref={activityEndRef} />
        </List>
      )}
    </Scrollbar>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={drawer.onTrue}
        sx={{
          width: 36,
          height: 36,
          transition: (theme) => theme.transitions.create(['background-color']),
          ...(drawer.value && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08)
          })
        }}
      >
        <Badge
          badgeContent={newActivitiesCount}
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              right: -2,
              top: 4,
              minWidth: 16,
              height: 16,
              fontSize: 10
            }
          }}
        >
          <Iconify icon="solar:bell-bing-bold-duotone" width={22} sx={{ color: '#1976D2' }} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true }
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 }
        }}
      >
        {renderHead}

        <Divider />

        {renderList}

        <Box sx={{ p: 1 }}></Box>
      </Drawer>
    </>
  );
}
