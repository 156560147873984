// SOL MENU

import { useMemo } from 'react';
import { paths } from 'src/routes/paths';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const ICONS = {
  // Overview
  dashboard: <Iconify icon="ph:chart-pie-slice-duotone" sx={{ color: '#00B8D9' }} />,
  app: <Iconify icon="ph:squares-four-duotone" sx={{ color: '#00B8D9' }} />,

  // Production
  production: <Iconify icon="ph:factory-duotone" sx={{ color: '#36B37E' }} />,
  project: <Iconify icon="ph:briefcase-duotone" sx={{ color: '#36B37E' }} />,
  projectList: <Iconify icon="ph:folders-duotone" sx={{ color: '#36B37E' }} />,
  projectNew: <Iconify icon="ph:folder-plus-duotone" sx={{ color: '#36B37E' }} />,
  workorder: <Iconify icon="ph:clipboard-text-duotone" sx={{ color: '#36B37E' }} />,
  tasks: <Iconify icon="ph:list-checks-duotone" sx={{ color: '#36B37E' }} />,
  designTasks: <Iconify icon="ph:pen-nib-duotone" sx={{ color: '#36B37E' }} />,
  orderTasks: <Iconify icon="ph:shopping-cart-duotone" sx={{ color: '#36B37E' }} />,
  shipmentTasks: <Iconify icon="ph:truck-duotone" sx={{ color: '#36B37E' }} />,

  // Catalog
  catalog: <Iconify icon="ph:package-duotone" sx={{ color: '#FF5630' }} />,
  catalogManagement: <Iconify icon="ph:stack-duotone" sx={{ color: '#FF5630' }} />,
  catalogList: <Iconify icon="ph:package-duotone" sx={{ color: '#FF5630' }} />,
  catalogNew: <Iconify icon="ph:package-plus-duotone" sx={{ color: '#FF5630' }} />,

  // Orders & Sales
  sales: <Iconify icon="ph:storefront-duotone" sx={{ color: '#FFAB00' }} />,
  order: <Iconify icon="ph:shopping-cart-duotone" sx={{ color: '#FFAB00' }} />,
  orderManagement: <Iconify icon="ph:shopping-bag-duotone" sx={{ color: '#FFAB00' }} />,
  orderList: <Iconify icon="ph:list-numbers-duotone" sx={{ color: '#FFAB00' }} />,
  orderNew: <Iconify icon="ph:note-plus-duotone" sx={{ color: '#FFAB00' }} />,

  // Users & Customers
  management: <Iconify icon="ph:gear-six-duotone" sx={{ color: '#7C5DFA' }} />,
  user: <Iconify icon="ph:users-three-duotone" sx={{ color: '#7C5DFA' }} />,
  userManagement: <Iconify icon="ph:users-duotone" sx={{ color: '#7C5DFA' }} />,
  userList: <Iconify icon="ph:user-list-duotone" sx={{ color: '#7C5DFA' }} />,
  userNew: <Iconify icon="ph:user-plus-duotone" sx={{ color: '#7C5DFA' }} />,
  authorization: <Iconify icon="ph:shield-check-duotone" sx={{ color: '#7C5DFA' }} />,
  customer: <Iconify icon="ph:user-circle-duotone" sx={{ color: '#7C5DFA' }} />,
  customerManagement: <Iconify icon="ph:users-four-duotone" sx={{ color: '#7C5DFA' }} />,
  customerList: <Iconify icon="ph:user-list-duotone" sx={{ color: '#7C5DFA' }} />,
  customerNew: <Iconify icon="ph:user-plus-duotone" sx={{ color: '#7C5DFA' }} />,
  leads: <Iconify icon="ph:user-focus-duotone" sx={{ color: '#7C5DFA' }} />,

  // Add shipment icon
  shipment: <Iconify icon="ph:truck-duotone" sx={{ color: '#FFAB00' }} />,
  shipmentList: <Iconify icon="ph:package-duotone" sx={{ color: '#FFAB00' }} />
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      // Overview - Always accessible
      {
        subheader: t('overview'),
        items: [
          {
            title: t('app'),
            path: paths.dashboard.root,
            icon: ICONS.app
          }
        ]
      },
      // Production
      {
        subheader: t('production'),
        items: [
          {
            title: t('project-management'),
            path: paths.production.projectManagement.root,
            icon: ICONS.project,
            permissions: ['Project.View.ALL'],
            children: [
              {
                title: t('project-list'),
                path: paths.production.projectManagement.root,
                icon: ICONS.projectList,
                permissions: ['Project.View.ALL']
              },
              {
                title: t('project-new'),
                path: paths.production.projectManagement.create,
                icon: ICONS.projectNew,
                permissions: ['Project.Create.ALL']
              }
            ]
          },
          {
            title: 'Görevler',
            path: paths.taskQueue.root,
            icon: ICONS.tasks,
            permissions: ['Task.View.ALL'],
            children: [
              {
                title: 'Tüm Görevler',
                path: paths.taskQueue.root,
                icon: ICONS.tasks,
                permissions: ['Task.View.ALL']
              },
              {
                title: 'Tasarım Görevleri',
                path: paths.taskQueue.design,
                icon: ICONS.designTasks,
                permissions: ['Task.View.ALL']
              },
              {
                title: 'Sipariş Görevleri',
                path: paths.taskQueue.order,
                icon: ICONS.orderTasks,
                permissions: ['Task.View.ALL']
              },
              {
                title: 'Gönderi Görevleri',
                path: paths.taskQueue.shipment,
                icon: ICONS.shipmentTasks,
                permissions: ['Task.View.ALL']
              }
            ]
          }
        ]
      },
      // Orders & Sales
      {
        subheader: 'Satış',
        items: [
          {
            title: t('order-management'),
            path: paths.order.root,
            icon: ICONS.orderManagement,
            permissions: ['Order.View.ALL'],
            children: [
              {
                title: t('order-list'),
                path: paths.order.root,
                icon: ICONS.orderList,
                permissions: ['Order.View.ALL']
              },
              {
                title: t('order-create'),
                path: paths.order.new,
                icon: ICONS.orderNew,
                permissions: ['Order.Create.ALL']
              }
            ]
          },
          {
            title: 'Gönderiler',
            path: paths.shipments.root,
            icon: ICONS.shipment,
            permissions: ['Order.View.ALL'],
            children: [
              {
                title: 'Gönderi Listesi',
                path: paths.shipments.list,
                icon: ICONS.shipmentList,
                permissions: ['Order.View.ALL']
              }
            ]
          }
        ]
      },
      // Catalog
      {
        subheader: t('catalog'),
        icon: ICONS.catalog,
        items: [
          {
            title: t('catalog-management'),
            path: paths.catalog.catalogManagement.root,
            icon: ICONS.catalogManagement,
            permissions: ['Catalog.View.ALL'],
            children: [
              {
                title: t('catalog-list'),
                path: paths.catalog.catalogManagement.root,
                icon: ICONS.catalogList,
                permissions: ['Catalog.View.ALL']
              }
            ]
          }
        ]
      },
      // Management
      {
        subheader: t('management'),
        icon: ICONS.management,
        items: [
          {
            title: t('customer-management'),
            path: paths.customer.root,
            icon: ICONS.customerManagement,
            permissions: ['Customer.View.ALL'],
            children: [
              {
                title: t('customer-list'),
                path: paths.customer.customerManagement.root,
                icon: ICONS.customerList,
                permissions: ['Customer.View.ALL']
              },
              {
                title: t('customer-new'),
                path: paths.customer.customerManagement.create,
                icon: ICONS.customerNew,
                permissions: ['Customer.Create.ALL']
              },
              {
                title: 'Müşteri Adayları',
                path: paths.customer.leads.root,
                icon: ICONS.leads,
                permissions: ['Customer.View.ALL']
              }
            ]
          },
          {
            title: t('user-management'),
            path: paths.user.root,
            icon: ICONS.userManagement,
            permissions: ['User.View.ALL'],
            children: [
              {
                title: t('user-list'),
                path: paths.user.list,
                icon: ICONS.userList,
                permissions: ['User.View.ALL']
              },
              {
                title: t('user-new'),
                path: paths.user.new,
                icon: ICONS.userNew,
                permissions: ['User.Create.ALL']
              },
              {
                title: 'Authorization',
                path: paths.dashboard.authorization,
                icon: ICONS.authorization,
                permissions: ['Role.View.ALL']
              }
            ]
          }
        ]
      }
    ],
    [t]
  );

  return data;
}
