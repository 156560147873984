const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  PRODUCTION: '/production',
  CATALOG: '/catalog',
  CUSTOMER: '/customer',
  USER: '/user',
  ORDER: '/order',
  WORKORDER: '/workorder',
  TASKQUEUE: '/taskqueues',
  SHIPMENTS: '/shipments'
};

export const paths = {
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`
    }
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    app: `${ROOTS.DASHBOARD}/app`,
    projectmanagement: `${ROOTS.DASHBOARD}/project-management`,
    authorization: `${ROOTS.DASHBOARD}/authorization`
  },
  user: {
    root: `${ROOTS.USER}`,
    new: `${ROOTS.USER}/new`,
    list: `${ROOTS.USER}/list`,
    cards: `${ROOTS.USER}/cards`,
    profile: `${ROOTS.USER}/profile`,
    account: `${ROOTS.USER}/account`,
    edit: (id) => `${ROOTS.USER}/${id}/edit`
  },
  production: {
    root: ROOTS.PRODUCTION,
    projectManagement: {
      root: `${ROOTS.PRODUCTION}/project`,
      edit: (id) => `${ROOTS.PRODUCTION}/project/${id}/edit`,
      details: (id) => `${ROOTS.PRODUCTION}/project/${id}`,
      create: `${ROOTS.PRODUCTION}/project/new`,
      variants: {
        // details: (id, variantId) => `${ROOTS.PRODUCTION}/project/${id}/variants/${variantId}`,
        details: (variantId) => `${ROOTS.PRODUCTION}/project/variants/${variantId}`,
        create: (id) => `${ROOTS.PRODUCTION}/project/${id}/variants/new`,
        submissions: {
          detail: (variantId, submissionId) =>
            `${ROOTS.PRODUCTION}/project/variants/${variantId}/submssions/${submissionId}`
        }
      }
    }
  },
  catalog: {
    root: ROOTS.CATALOG,
    catalogManagement: {
      root: `${ROOTS.CATALOG}`,
      create: `${ROOTS.CATALOG}/new`
    }
  },
  customer: {
    root: ROOTS.CUSTOMER,
    customerManagement: {
      root: `${ROOTS.CUSTOMER}`,
      create: `${ROOTS.CUSTOMER}/new`
    },
    leads: {
      root: `${ROOTS.CUSTOMER}/leads`,
      details: (id) => `${ROOTS.CUSTOMER}/leads/${id}`,
      edit: (id) => `${ROOTS.CUSTOMER}/leads/${id}/edit`
    },
    create: `${ROOTS.CUSTOMER}/new`
  },
  order: {
    root: `${ROOTS.ORDER}`,
    details: (id) => `${ROOTS.ORDER}/${id}`,
    new: `${ROOTS.ORDER}/new`
  },
  workOrder: {
    root: `${ROOTS.WORKORDER}`,
    details: (id) => `${ROOTS.WORKORDER}/${id}`
  },
  taskQueue: {
    root: `${ROOTS.TASKQUEUE}`,
    details: (id) => `${ROOTS.TASKQUEUE}/${id}`,
    design: `${ROOTS.TASKQUEUE}/design`,
    order: `${ROOTS.TASKQUEUE}/order`,
    shipment: `${ROOTS.TASKQUEUE}/shipment`
  },
  shipments: {
    root: ROOTS.SHIPMENTS,
    list: ROOTS.SHIPMENTS
  }
};
