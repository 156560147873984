import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// auth
import { AuthGuard, ProtectedElement } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const AuthorizationPage = lazy(() => import('src/pages/dashboard/authorization'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      { path: 'app', element: <IndexPage /> },
      {
        path: 'authorization',
        element: (
          <ProtectedElement requiredPermission="User.Edit.ALL" requiredGroup="UserManagement">
            <AuthorizationPage />
          </ProtectedElement>
        )
      }
    ]
  }
];
